<template>
    <div v-if="!initializing">
        <router-view></router-view>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                initializing: true
            }
        },
        methods: {
            ...mapActions('system', ['initSystem'])
        },
        created() {
            this.initSystem().then(() => {
                this.initializing = false;
                this.appSettings.format = { dateTime: "HH:mm DD-MM-YYYY", date: "DD-MM-YYYY", time: "HH:mm" }
            });
        },
    };
</script>
