<template>
    <div style="margin:100px auto; text-align:center;">
        <div class="lds-dual-ring"></div>
    </div>
</template>
<style>
    .lds-dual-ring { display: inline-block; width: 80px; height: 80px; }
        .lds-dual-ring:after { content: " "; display: block; width: 64px; height: 64px; margin: 8px; border-radius: 50%; border: 6px solid #ed1d26; border-color: #ed1d26 transparent #ed1d26 transparent; animation: lds-dual-ring 1.2s linear infinite; }

    @keyframes lds-dual-ring {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>
<script type="text/javascript">
    export default {
        async created() {
            this.$auth.getUser().then((user) => {
                if (user) {
                    this.$router.push('/manager');
                } else {
                    this.$auth.signIn();
                }
            });
        },
    };
</script>
