<template>
    <div v-if="currentUser && !initializing">
        <section class="block-user-page">
            <div class="container-xxl">
                <div class="layout-block-user-page">
                    <left-menu />
                    <router-view></router-view>                    
                    <right-menu />
                </div>
            </div>
        </section>
        <auth />
    </div>
</template>
<script>
    import ui from "../store/ui";
    import leftMenu from '../components/layout/left-menu.vue'
    import rightMenu from '../components/layout/right-menu.vue'
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import auth from '../components/system/auth'
    export default {
        components: { auth, leftMenu, rightMenu },
        data() {
            return {
                initializing: true
            }
        },
        async created() {
            this.$auth.getProfile().then((profile) => {
                this.setUserInfo(profile);
            });
            this.initSystem().then(() => {
                this.appSettings.format = { dateTime: "HH:mm DD-MM-YYYY", date: "DD-MM-YYYY", time: "HH:mm" }
                this.initializing = false;
            });
        },
        methods: {
            ...mapMutations({ setUserInfo: 'user/updateInfo' }),
            ...mapActions('system', ['initSystem']),
        },
        computed: {
            ...mapGetters(['isOpenMenuMobile']),
        },
        beforeCreate() {
            if (!this.$store._modulesNamespaceMap["ui/"])
                this.$store.registerModule("ui", ui);
        }
    };
</script>
