<template>
    <div class="main-block-user">
        <div class="title-tab-user">
            <div class="content">
                <h3 class="name">Xin chào Anh Tú!</h3>
                <p class="text-sub">Là thành viên từ tháng 11/2024</p>
                <p class="user-level silver">
                    <span>Hạng thành viên: <b>Miễn phí</b></span>
                    <a href="https://phathanh.vneconomy.vn/#premium" target="_blank">Mua gói Premium</a>
                </p>
            </div>
            <div class="box-avatar">
                <img class="image-avatar" src="https://mighty.tools/mockmind-api/content/human/7.jpg" alt="">
                <div class="icon-upload">
                    <img src="@/assets/img-fix/camera.svg" alt="">
                </div>
            </div>
        </div>
        <div class="main-tab-user">
            <div class="block-info-user">
                <div class="title-site">
                    <p class="name">Thông tin tài khoản</p>
                </div>
                <div class="main-block">
                    <div class="form-info-user">
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Tên hiển thị</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content">Phùng Anh Tú</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Email</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content">tu*******@gmail.com</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Mật khẩu</p>
                            </div>
                            <div class="td-content">
                                <div class="password-hidden">
                                    <input type="password" value="manhtuong">
                                </div>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Số điện thoại</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content text-no-data">Chưa có thông tin</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Ngày sinh</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content">26/10/1989</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Giới tính</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content text-no-data">Chưa có thông tin</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Địa điểm</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content">21 Huỳnh Thúc Kháng, Đống Đa, Hà Nội</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                        <div class="tr-form-user">
                            <div class="td-name">
                                <p>Nghề nghiệp</p>
                            </div>
                            <div class="td-content">
                                <p class="text-content">Báo chí</p>
                            </div>
                            <div class="td-action">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Chỉnh sửa</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-info-user">
                <div class="title-site">
                    <p class="name">Liên kết tài khoản</p>
                </div>
                <div class="main-block">
                    <div class="block-link-share">
                        <div class="imt-link-share">
                            <span class="text">Liên kết tài khoản Facebook</span>
                            <span class="icon">
                                <img src="@/assets/img-fix/facebook.png" alt="">
                            </span>
                        </div>
                        <div class="imt-link-share active">
                            <span class="text">Hủy liên kết tài khoản Google</span>
                            <span class="icon">
                                <img src="@/assets/img-fix/google.png" alt="">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-info-user">
                <div class="title-site">
                    <p class="name">Tùy chỉnh nâng cao</p>
                </div>
                <div class="main-block">
                    <div class="block-setting-advanced">
                        <div class="imt-setting">
                            <div class="content-imt-setting">
                                <p class="name-setting">Bảo mật 2 lớp</p>
                                <p class="text-sub">Khi tính năng được kích hoạt, chúng tôi sẽ gửi mã xác thực qua email của bạn mỗi khi bạn sử dụng mật khẩu để đăng nhập.</p>
                            </div>
                            <div class="arrow-imt-setting">
                                <div class="btn-bg-linear-style2">
                                    <a href="javascript:;">Kích hoạt</a>
                                </div>
                            </div>
                        </div>
                        <div class="imt-setting">
                            <div class="content-imt-setting">
                                <p class="name-setting">Xóa tài khoản</p>
                                <p class="text-sub">
                                    Tất cả tin đã lưu và lịch sử xem sẽ bị xóa.
                                </p>
                                <p class="text-sub">
                                    Người dùng khác vẫn có thể thấy bình luận và lượt thích của bạn.
                                </p>
                            </div>
                            <div class="arrow-imt-setting">
                                <div class="btn-border">
                                    <a href="javascript:;">
                                        <span class="arrow">

                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.80469 2.15625L4.33594 2.85938H8.75977L8.29102 2.15625C8.20312 2.00977 8.05664 1.92188 7.91016 1.92188H5.18555C5.03906 1.92188 4.89258 2.00977 4.80469 2.15625ZM9.11133 1.6582L9.84375 2.85938H11.25H12.1875H12.6562C12.8906 2.85938 13.125 3.09375 13.125 3.32812C13.125 3.5918 12.8906 3.79688 12.6562 3.79688H12.1875V13.6406C12.1875 14.959 11.1328 15.9844 9.84375 15.9844H3.28125C1.96289 15.9844 0.9375 14.959 0.9375 13.6406V3.79688H0.46875C0.205078 3.79688 0 3.5918 0 3.32812C0 3.09375 0.205078 2.85938 0.46875 2.85938H0.9375H1.875H3.25195L4.01367 1.6582C4.24805 1.24805 4.7168 0.984375 5.18555 0.984375H7.91016C8.37891 0.984375 8.84766 1.24805 9.08203 1.6582H9.11133ZM1.875 3.79688V13.6406C1.875 14.4316 2.49023 15.0469 3.28125 15.0469H9.84375C10.6055 15.0469 11.25 14.4316 11.25 13.6406V3.79688H1.875ZM4.21875 6.14062V12.7031C4.21875 12.9668 3.98438 13.1719 3.75 13.1719C3.48633 13.1719 3.28125 12.9668 3.28125 12.7031V6.14062C3.28125 5.90625 3.48633 5.67188 3.75 5.67188C3.98438 5.67188 4.21875 5.90625 4.21875 6.14062ZM7.03125 6.14062V12.7031C7.03125 12.9668 6.79688 13.1719 6.5625 13.1719C6.29883 13.1719 6.09375 12.9668 6.09375 12.7031V6.14062C6.09375 5.90625 6.29883 5.67188 6.5625 5.67188C6.79688 5.67188 7.03125 5.90625 7.03125 6.14062ZM9.84375 6.14062V12.7031C9.84375 12.9668 9.60938 13.1719 9.375 13.1719C9.11133 13.1719 8.90625 12.9668 8.90625 12.7031V6.14062C8.90625 5.90625 9.11133 5.67188 9.375 5.67188C9.60938 5.67188 9.84375 5.90625 9.84375 6.14062Z" fill="black"></path>
                                            </svg>

                                        </span>
                                        <span class="text">Xóa</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<layout-main>
        <h2 class="name-page" slot="left">Thông tin cá nhân</h2>
        <div class="block-content-tab-follow">
            <div class="item-content-tab-follow ct-style-block">
                <div class="block-edit-info-page">
                    <div class="content-edit-info-page">
                        <div class="lst-info-user">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-info-new open-form">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Họ và tên</label>
                                            <input type="text" class="" v-model="userInfo.fullName">
                                            <i>Nhập đầy đủ Họ và tên (chứa dấu cách), ví dụ: Nguyễn Văn A</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-info-new ">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Điện thoại</label>
                                            <input type="number" class="" v-model="userInfo.phoneNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="group-ns-email">

                                        <div class="form-info-new form-mail-fix">
                                            <div class="ct-input-info-new">
                                                <label class="label-bold">Email</label>
                                                <input type="text" class="" v-model="userInfo.email">
                                                <span class="icon-lock">
                                                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.75 7.33083V4.91898C12.748 3.61498 12.2172 2.36495 11.2738 1.44288C10.3305 0.52081 9.05159 0.00193904 7.7175 0H7.2825C5.94841 0.00193904 4.66952 0.52081 3.72618 1.44288C2.78283 2.36495 2.25198 3.61498 2.25 4.91898V7.33083C1.65326 7.33083 1.08097 7.56253 0.65901 7.97497C0.237053 8.38741 0 8.9468 0 9.53007V16.3477C0.00198284 17.4554 0.45302 18.5171 1.25431 19.3003C2.0556 20.0835 3.14181 20.5244 4.275 20.5263H10.725C11.8582 20.5244 12.9444 20.0835 13.7457 19.3003C14.547 18.5171 14.998 17.4554 15 16.3477V9.53007C15 8.9468 14.7629 8.38741 14.341 7.97497C13.919 7.56253 13.3467 7.33083 12.75 7.33083ZM3.75 4.91898C3.75 4.00324 4.12217 3.125 4.78465 2.47747C5.44712 1.82994 6.34562 1.46617 7.2825 1.46617H7.7175C8.65438 1.46617 9.55288 1.82994 10.2154 2.47747C10.8778 3.125 11.25 4.00324 11.25 4.91898V7.33083H3.75V4.91898ZM13.5 16.3477C13.5 17.0671 13.2076 17.757 12.6872 18.2657C12.1668 18.7744 11.461 19.0601 10.725 19.0601H4.275C3.53902 19.0601 2.83319 18.7744 2.31278 18.2657C1.79237 17.757 1.5 17.0671 1.5 16.3477V9.53007C1.5 9.33565 1.57902 9.14919 1.71967 9.01171C1.86032 8.87423 2.05109 8.79699 2.25 8.79699H12.75C12.9489 8.79699 13.1397 8.87423 13.2803 9.01171C13.421 9.14919 13.5 9.33565 13.5 9.53007V16.3477Z" fill="#777E8A" />
                                                        <path d="M8.25 13.7233V16.1278C8.25 16.3222 8.17098 16.5087 8.03033 16.6462C7.88968 16.7837 7.69891 16.8609 7.5 16.8609C7.30109 16.8609 7.11032 16.7837 6.96967 16.6462C6.82902 16.5087 6.75 16.3222 6.75 16.1278V13.7233C6.46404 13.5619 6.24054 13.3128 6.11418 13.0146C5.98782 12.7165 5.96565 12.3859 6.05111 12.0741C6.13657 11.7623 6.32489 11.4869 6.58686 11.2904C6.84882 11.0939 7.1698 10.9874 7.5 10.9874C7.8302 10.9874 8.15118 11.0939 8.41314 11.2904C8.67511 11.4869 8.86343 11.7623 8.94889 12.0741C9.03435 12.3859 9.01218 12.7165 8.88582 13.0146C8.75946 13.3128 8.53596 13.5619 8.25 13.7233Z" fill="#777E8A" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <select-date-drop v-model="userInfo.birthdate"></select-date-drop>
                                    </div>

                                </div>

                                <div class="col-12">
                                    <div class="form-info-new ">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Địa chỉ</label>
                                            <input type="text" class="" v-model="userInfo.address">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-popup-action">
                        <div class="left-bottom-popup-action">
                            <div class="btn-cancel">
                                <a href="javascript:;" @click="resetData" class="btn-text">Đặt lại</a>
                            </div>
                        </div>
                        <div class="right-bottom-popup-action">
                            <div class="btn-save">
                                <a href="javascript:;" @click="updateGeneralInfo" class="btn-bg-linear">Lưu thay đổi</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </layout-main>-->
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    export default {
        props: ['iframe'],
        components: {
            /*modalcustom*/
        },
        data() {
            return {
                isLoaded: false,
                isEdit: false,
                userInfo: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    birthdate: null,
                    gender: null,
                    languageId: null,
                    userClaims: null,
                    gender_text: null,
                    language_text: null,
                    birthdate_text: null,
                },
                showLink: false,
                currentClaim: {},
                genderOptions: [],
                languageOptions: [],
                dayOptions: [],
                submitted: false,
                client_configs: {},
            };
        },
        validations: {
            userInfo: {
                fullName: { required }
            },
        },
        created() {
            //this.client_configs = this.appSettings.configs;
            this.getUserInfo();
            if (this.iframe)
                this.editProfile()
        },
        mounted() {
        },
        methods: {
            getUserInfo() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_GET_PROFILE)
                    .then((data) => {
                        this.userInfo = data.result;
                        //var default_language = data.result.userClaims.find(
                        //    (x) => x.claimType == this.appSettings.userClaimType.DefaultLanguage
                        //);
                        //if (default_language) {
                        //    this.userInfo.languageId = default_language.claimValue;
                        //}
                        //this.mappingText();
                        $loading.hide();
                        // Convert Ngôn ngữ
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            mappingText() {
                if (this.userInfo.languageId) {
                    this.userInfo.language_text = this.languageOptions.find(
                        (x) => x.code === this.userInfo.languageId
                    ).name;
                }
                if (this.userInfo.birthdate) {
                    this.userInfo.birthdate_text = new Date(this.userInfo.birthdate)
                        .toJSON()
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/");
                }
                if (this.userInfo.gender) {
                    var gender_value = this.genderOptions.find(
                        (x) => x.value === this.userInfo.gender
                    ).name;
                    this.userInfo.gender_text = this.$t(gender_value);
                }
            },
            updateGeneralInfo() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    this.$axios
                        .put(this.$api.USER_UPDATE_INFO, this.userInfo)
                        .then((data) => {
                            // this.getEmail();
                            this.$success(this.$t("info_noti_info_success"));
                            this.mappingText();
                            $loading.hide();

                            if (typeof (window) != undefined && this.iframe)
                                window.flutter_inappwebview.callHandler('updateSuccess', 'info');
                            else
                                this.isEdit = false;
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                }
            },
            updateUserClaim(userClaim, callback) {
                const $loading = this.$loading.show();
                this.$axios
                    .put(this.$api.USER_UPDATE_CLAIM, userClaim)
                    .then((data) => {
                        // this.getEmail();
                        this.$success(this.$t("info_noti_info_success"));
                        $loading.hide();
                        callback();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            editProfile() {
                this.isEdit = true;
            },
            showLinkTo(type) {
                let claim = this.userInfo.userClaims.find((x) => x.claimType == type);
                if (claim) {
                    this.currentClaim = claim;
                } else {
                    this.currentClaim = {
                        ClaimType: type,
                        ClaimValue: null,
                    };
                }
                this.showLink = true;
            },
            hideLinkTo() {
                this.showLink = false;
            },
            loginViaFacebook(info) {
                var vm = this;
                if (info && info.token && info.email && info.id) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.FacebookLink,
                        ClaimValue: info.email,
                    };
                    this.updateUserClaim(currentClaim, () => {
                        vm.facebook_link = info.email;
                    });
                } else {
                    this.$error(this.$t("login_invalid_external_info"));
                }
            },
            loginViaGoogle(info) {
                var vm = this;
                if (info && info.token && info.email && info.id) {
                    var currentClaim = {
                        ClaimType: this.appSettings.userClaimType.GoogleLink,
                        ClaimValue: info.email,
                    };
                    this.updateUserClaim(currentClaim, () => {
                        vm.google_link = info.email;
                    });
                } else {
                    this.$error(this.$t("login_invalid_external_info"));
                }
            },
            logoutGoogle() {
                var vm = this;
                this.$confirm(
                    this.$t("address_confirm_delete"),
                    this.$t("address_confirm_confirm"),
                    this.$t("address_confirm_cancel")
                ).then(() => {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.signOut().then(function () {
                        var currentClaim = {
                            ClaimType: vm.appSettings.userClaimType.GoogleLink,
                            ClaimValue: "",
                        };
                        vm.updateUserClaim(currentClaim, () => {
                            vm.google_link = "";
                        });
                    });
                    auth2.disconnect();
                });
            },
            logoutFacebook() {
                var vm = this;
                this.$confirm(
                    this.$t("address_confirm_delete"),
                    this.$t("address_confirm_confirm"),
                    this.$t("address_confirm_cancel")
                ).then(() => {
                    FB.getLoginStatus(function (response) {
                        if (response && response.status === "connected") {
                            FB.logout(function (response) {
                                var currentClaim = {
                                    ClaimType: vm.appSettings.userClaimType.FacebookLink,
                                    ClaimValue: "",
                                };
                                vm.updateUserClaim(currentClaim, () => {
                                    vm.facebook_link = "";
                                });
                            });
                        }
                    });
                });
            },
            resetData() {

            }
        },
    };
</script>

<style scoped>
    .popup .modal-dialog { width: 400px; height: 304px; }
</style>
