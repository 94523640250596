<template>
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h1>404</h1>
            </div>
            <h2 class="mt-3">Trang không được tìm thấy</h2>
            <p>Trang đang được xây dựng hoặc không tồn tại trong hệ thống</p>
            <div class="btn-bg-linear-style2" style="margin-top:50px;">
                <router-link to="/" class="btn-border">Trang chủ</router-link>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        mounted() { },
    };
</script>

<style scoped>
    * { -webkit-box-sizing: border-box; box-sizing: border-box; }

    body { padding: 0; margin: 0; }

    #notfound { position: relative; }

        #notfound .notfound { position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); }

    .notfound { max-width: 767px; width: 100%; line-height: 1.4; padding: 0px 15px; }

        .notfound .notfound-404 { position: relative; height: 150px; line-height: 150px; margin-bottom: 25px; }

            .notfound .notfound-404 h1 { font-size: 186px; font-weight: 900; margin: 0px; text-transform: uppercase; /*background: url("../assets/images/text.png");*/ -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-size: cover; background-position: center; }

        .notfound h2 { font-size: 26px; font-weight: 700; margin: 0; margin-top: 40px !important; margin-bottom: 10px; }

        .notfound p { font-size: 14px; font-weight: 500; text-transform: uppercase; }


    @media only screen and (max-width: 767px) {
        .notfound .notfound-404 { height: 110px; line-height: 110px; }
            .notfound .notfound-404 h1 { font-size: 120px; }
    }
</style>
