<template>
    <header class="site-header" id="site-header">
        <div class="container-xxl">
            <div class="site-header__topbar">
                <div class="stock" id="indices"></div>
                <div class="links">
                    <a href="javascript:;" class="link-icon">
                        <img src="@/assets/img-fix/flag-en.png" alt="" class="img-fluid">
                    </a>
                    <a href="javascript:;" target="_blank" class="link-icon">
                        <i class="icon-facebook"></i>
                    </a>
                    <a href="javascript:;" class="link-icon">
                        <i class="icon-rss"></i>
                    </a>
                    <div class="user-header link-icon" v-click-outside="onClickOutside">
                        <div class="box-user">
                            <i class="icon-user"></i>
                        </div>
                        <div class="popup-user-header" v-if="currentUser">
                            <div class="title-user-header">
                                <div class="avatar">
                                    <img src="https://mighty.tools/mockmind-api/content/human/7.jpg" alt="">
                                </div>
                                <p class="name">Anh Tú</p>
                            </div>
                            <div class="main-link-user-header">
                                <div class="imt-user">
                                    <router-link to="/manager/info" class="link-user">
                                        <span class="icon">

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.875 7.5C14.875 6.40625 14.25 5.39062 13.3125 4.80469C12.3359 4.25781 11.125 4.25781 10.1875 4.80469C9.21094 5.39062 8.625 6.40625 8.625 7.5C8.625 8.63281 9.21094 9.64844 10.1875 10.2344C11.125 10.7812 12.3359 10.7812 13.3125 10.2344C14.25 9.64844 14.875 8.63281 14.875 7.5ZM6.75 7.5C6.75 5.74219 7.6875 4.10156 9.25 3.20312C10.7734 2.30469 12.6875 2.30469 14.25 3.20312C15.7734 4.10156 16.75 5.74219 16.75 7.5C16.75 9.29688 15.7734 10.9375 14.25 11.8359C12.6875 12.7344 10.7734 12.7344 9.25 11.8359C7.6875 10.9375 6.75 9.29688 6.75 7.5ZM4.91406 20.625H18.5469C18.1953 18.1641 16.0859 16.25 13.5078 16.25H9.95312C7.375 16.25 5.26562 18.1641 4.91406 20.625ZM3 21.3672C3 17.5 6.08594 14.375 9.95312 14.375H13.5078C17.375 14.375 20.5 17.5 20.5 21.3672C20.5 21.9922 19.9531 22.5 19.3281 22.5H4.13281C3.50781 22.5 3 21.9922 3 21.3672Z" fill="black"></path>
                                            </svg>

                                        </span>
                                        <span class="name">Thông tin chung</span>
                                    </router-link>
                                </div>
                                <div class="imt-user">
                                    <router-link to="/manager/history" class="link-user">
                                        <span class="icon">

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.875 6.67969C5.67188 4.17969 8.64062 2.5 12 2.5C17.5078 2.5 22 6.99219 22 12.5C22 18.0469 17.5078 22.5 12 22.5C9.92969 22.5 8.05469 21.9141 6.45312 20.8594C6.02344 20.5859 5.90625 20 6.21875 19.5703C6.49219 19.1406 7.07812 19.0234 7.50781 19.2969C8.79688 20.1562 10.3203 20.625 12 20.625C16.4531 20.625 20.125 16.9922 20.125 12.5C20.125 8.04688 16.4531 4.375 12 4.375C9.10938 4.375 6.57031 5.89844 5.125 8.125H7.3125C7.82031 8.125 8.25 8.55469 8.25 9.0625C8.25 9.60938 7.82031 10 7.3125 10H2.9375C2.39062 10 2 9.60938 2 9.0625V4.6875C2 4.17969 2.39062 3.75 2.9375 3.75C3.44531 3.75 3.875 4.17969 3.875 4.6875V6.67969ZM12 7.5H11.9609C12.5078 7.5 12.8984 7.92969 12.8984 8.4375V12.1484L15.4375 14.6875C15.8281 15.0391 15.8281 15.625 15.4375 15.9766C15.0859 16.3672 14.5 16.3672 14.1484 15.9766L11.3359 13.1641C11.1406 13.0078 11.0625 12.7734 11.0625 12.5V8.4375C11.0625 7.92969 11.4531 7.5 12 7.5Z" fill="black"></path>
                                            </svg>

                                        </span>
                                        <span class="name">Lịch sử xem</span>
                                    </router-link>
                                </div>
                                <div class="imt-user">
                                    <router-link to="/manager/comments" class="link-user">
                                        <span class="icon">

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 7H17V9H7V7ZM7 11H14V13H7V11Z" fill="black"></path>
                                                <path d="M20 2H4C2.897 2 2 2.897 2 4V22L7.333 18H20C21.103 18 22 17.103 22 16V4C22 2.897 21.103 2 20 2ZM20 16H6.667L4 18V4H20V16Z" fill="black"></path>
                                            </svg>

                                        </span>
                                        <span class="name">Bình luận của bạn (26)</span>
                                    </router-link>
                                </div>
                                <div class="imt-user">
                                    <router-link to="/manager/saved" class="link-user">
                                        <span class="icon">

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.5 4.375C4.5 3.35938 5.32031 2.5 6.375 2.5V4.375V19.7656L11.4531 16.1328C11.7656 15.8984 12.1953 15.8984 12.5469 16.1328L17.625 19.7656V4.375H6.375V2.5H17.625C18.6406 2.5 19.5 3.35938 19.5 4.375V21.5625C19.5 21.9141 19.3047 22.2656 18.9922 22.4219C18.6797 22.5781 18.2891 22.5391 18.0156 22.3438L12 18.0469L5.94531 22.3438C5.67188 22.5391 5.32031 22.5781 5.00781 22.4219C4.69531 22.2656 4.5 21.9141 4.5 21.5625V4.375Z" fill="black"></path>
                                            </svg>

                                        </span>
                                        <span class="name">Tin đã lưu (10)</span>
                                    </router-link>
                                </div>
                            </div>
                            <div class="bottom-user">
                                <div class="btn-logout">
                                    <router-link to="/logout" class="link-logout">
                                        <span class="text">Đăng xuất</span>
                                        <span class="icon">

                                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.5625 2.625H1.875V16.375H6.5625H7.5V18.25H6.5625H0.9375H0V17.3125V1.6875V0.75H0.9375H6.5625H7.5V2.625H6.5625ZM19.2578 10.1641L14.2578 15.1641L13.5938 15.8281L12.2656 14.5L12.9297 13.8359L16.3281 10.4375H7.1875H6.25V8.5625H7.1875H16.3281L12.9297 5.16406L12.2656 4.5L13.5938 3.17188L14.2578 3.83594L19.2578 8.83594L19.9219 9.5L19.2578 10.1641Z" fill="black"></path>
                                            </svg>

                                        </span>
                                    </router-link>
                                </div>
                            </div>
                            <div class="close-popup"><i class="fa-regular fa-xmark"></i></div>
                        </div>
                    </div>
                    <a href="javascript:;" class="link-icon" id="searchDesktop">
                        <i class="icon-search"></i><i class="icon-times"></i>
                    </a>
                    <div class="input-wrap link-icon">
                        <input type="text" class="form-control" placeholder="Tìm kiếm ..." id="txtSearchTwo">
                        <a href="javascript:void(0)" class="icon-search btnSearch"></a>
                    </div>
                </div>
            </div>
            <div class="site-header__toolbar">
                <!-- <a href="javascript:;" class="btn btn-outline-dark btn-sm  m-btn btn-subscribe">Subscribe</a> -->
                <span class="btn-search m-btn">
                    <i class="icon-search"></i>
                </span>
                <span class="btn-expand m-btn" href="javascript:;">
                    <i class="icon-bars"></i>
                    <i class="icon-times"></i>
                </span>
            </div>

            <div class="site-header__content bg_header">
                <div class="row">
                    <div class="col-md-3 d-none d-md-flex align-items-start mt-1 flex-tc-dt">
                        <img src="https://media.vneconomy.vn/App_themes/images/favicon.ico" alt="Icon" class="img-fluid mr-1" style="width:16px;">
                        <strong>TẠP CHÍ ĐIỆN TỬ</strong>
                    </div>
                    <div class="col-md-6 mx-auto">
                        <h3 class="logo">
                            <a href="javascript:;" title="VnEconomy" style="width: 100%; height: 100%;display: block">
                                <img class="img-fluid" src="https://media.vneconomy.vn/images/logo.png?v=2.2.4" data-src="https://media.vneconomy.vn/images/logo.png?v=2.2.4" alt="VnEconomy" title="VnEconomy">
                            </a>
                        </h3>
                    </div>
                    <div class="col-md-3">&nbsp;</div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="date align-self-end">
                            <time>Chủ Nhật, 17/11/2024</time>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="links">
                            <a class="link__extend" href="https://en.vneconomy.vn" title="">VET online</a>
                            <a class="link__extend" href="/chung-khoan.htm" title="">Chứng khoán</a>
                            <a class="link__extend" href="/tieu-dung.htm" title="Tiêu dùng">Tiêu &amp; Dùng</a>
                            <a class="link__extend" href="https://vneconomy.vn/automotive/" title="Xe">Xe</a>
                            <a class="link__extend" href="/video.htm" title="VNE TV">VnE TV</a>
                            <a class="link__extend" href="https://vneconomy.vn/techconnect/" title="Tech Connect">Tech Connect</a>
                            <a class="link__extend" href="https://en.vneconomy.vn" title="English ++">English ++</a>
                        </div>
                    </div>
                    <div class="col-md-2 order-md-3">
                        <div class="subscribe text-center">
                            <a class="" href="https://phathanh.vneconomy.vn" target="_blank" title="Đặt mua ấn phẩm">
                                <img src="https://media.vneconomy.vn/App_themes/images/anpham-vi.png" alt="Đặt mua ấn phẩm" style="height: 115px !important;" class="img-fluid">
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <nav>
                <ul class="nav">
                    <li class="nav-item nav-search">
                        <div class="input-wrap"><input id="txtSearchOne" type="text" class="form-control" placeholder="Từ khóa"><a href="javascript:void(0)" class="icon icon-search btnSearch"></a></div>
                    </li>
                    <li class="nav-item nav-links">
                        <a href="https://vneconomy.vn/video.htm" title="">VnE TV</a><a href="/chung-khoan.htm" title="">Chứng khoán</a><a href="/tieu-dung.htm" title="Tiêu &amp; Dùng">Tiêu &amp; Dùng</a><a href="https://vneconomy.vn/techconnect/" title="Tech connect">Tech connect</a><a href="https://vneconomy.vn/automotive/" title="Xe">Xe</a><a href="https://phathanh.vneconomy.vn/" target="_blank" title="Đặt mua ấn phẩm">Ấn phẩm</a><a href="https://en.vneconomy.vn" title="">
                            English
                            ++
                        </a>
                    </li>
                    <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/" title="Home"><i class="icon icon-home mr-1"></i></a></li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/tieu-diem.htm" title="Tiêu điểm">Tiêu điểm</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/dau-tu.htm" title="Đầu tư">Đầu tư</a>
                        <ul class="nav-sub">
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/ha-tang-dau-tu.htm" title="Hạ tầng">
                                    Hạ
                                    tầng
                                </a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/khung-phap-ly-dau-tu.htm" title="Khung pháp lý">Khung pháp lý</a></li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/dau-tu-du-an.htm" title="Dự án">
                                    Dự
                                    án
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/nha-dau-tu.htm" title="Nhà đầu tư">
                                    Nhà
                                    đầu tư
                                </a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/dau-tu-dia-phuong.htm" title="Địa phương">Địa phương</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/tai-chinh.htm" title="Tài chính">Tài chính</a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/tai-chinh-ngan-hang.htm" title="Ngân hàng">Ngân hàng</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thi-truong-von-tai-chinh.htm" title="Thị trường vốn">Thị trường vốn</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thue-tai-chhinh.htm" title="Thuế">Thuế</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/bao-hiem-tai-chinh.htm" title="Bảo hiểm">Bảo hiểm</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/kinh-te-so.htm" title="Kinh tế số">
                            Kinh tế
                            số
                        </a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/san-pham-thi-truong-kinh-te-so.htm" title="Sản phẩm - Thị trường">Sản phẩm - Thị trường</a></li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/fintech.htm" title="Fintech">Fintech</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/dich-vu-so.htm" title="Dịch vụ số">
                                    Dịch
                                    vụ số
                                </a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/start-up.htm" title="Start-up">Start-up</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/quan-tri-so.htm" title="Quản trị số">Quản trị số</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/kinh-te-xanh.htm" title="Kinh tế xanh">
                            Kinh tế
                            xanh
                        </a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/chuyen-dong-xanh.htm" title="Chuyển động xanh">Chuyển động xanh</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/dien-dan-kinh-te-xanh.htm" title="Diễn đàn">Diễn đàn</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/phap-ly-kinh-te-xanh.htm" title="Pháp lý">Pháp lý</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thuong-hieu-xanh.htm" title="Thương hiệu xanh">Thương hiệu xanh</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/thi-truong.htm" title="Thị trường">
                            Thị
                            trường
                        </a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/kin-te-thi-truong.htm" title="Thị trường">Thị trường</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thi-truong-nong-san.htm" title="Nông sản">Nông sản</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/khung-phap-ly-thi-truong.htm" title="Khung pháp lý">Khung pháp lý</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thi-truong-cong-nghiep.htm" title="Công nghiệp">Công nghiệp</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thi-truong-xuat-nhap-khau.htm" title="Xuất nhập khẩu">Xuất nhập khẩu</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/nhip-cau-doanh-nghiep.htm" title="Doanh nghiệp">Doanh nghiệp</a>
                        <ul class="nav-sub">
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/doanh-nhan.htm" title="Doanh nhân">
                                    Doanh
                                    nhân
                                </a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/cong-ty-doanh-nghiep.htm" title="Doanh nghiệp">Doanh nghiệp</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/chuyen-dong-doanh-nghiep.htm" title="Chuyển động">Chuyển động</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/doi-thoai-doanh-nghiep.htm" title="Đối thoại">Đối thoại</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/ket-noi-doanh-nghiep.htm" title="Kết nối">Kết nối</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/dia-oc.htm" title="Bất động sản">
                            Bất động
                            sản
                        </a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/chinh-sach-bat-dong-san.htm" title="Chính sách">Chính sách</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/thi-truong-bat-dong-san.htm" title="Thị trường">Thị trường</a></li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/du-an-bat-dong-san.htm" title="Dự án">
                                    Dự
                                    án
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/cafe-bds.htm" title="Cafe BĐS">
                                    Cafe
                                    BĐS
                                </a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/tu-van-bat-dong-san.htm" title="Tư vấn">Tư vấn</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/kinh-te-the-gioi.htm" title="Thế giới">
                            Thế
                            giới
                        </a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/kinh-doanh-the-gioi.htm" title="Kinh doanh">Kinh doanh</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/chuyen-dong-kinh-te-the-gioi-24h.htm" title="Chuyển động 24h">Chuyển động 24h</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/the-gioi-kinh-te.htm" title="Kinh tế">Kinh tế</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://vneconomy.vn/dan-sinh.htm" title="Dân sinh">Dân sinh</a>
                        <ul class="nav-sub">
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/khung-phap-ly-dan-sinh.htm" title="Khung pháp lý">Khung pháp lý</a></li>
                            <li class="nav-item"><a class="nav-link" href="https://vneconomy.vn/bao-hiem-dan-sinh.htm" title="Bảo hiểm">Bảo hiểm</a></li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/nhan-luc.htm" title="Nhân lực">
                                    Nhân
                                    lực
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/an-sinh.htm" title="An sinh">An sinh</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://vneconomy.vn/y-te-dan-sinh.htm" title="Y tế">Y tế</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item nav-magazine">
                        <h5><a class="text-light" href="https://postenp.phaha.vn/vi/chi-tiet-toa-soan/tap-chi-kinh-te-viet-nam" target="_blank">Ấn phẩm</a></h5><a href="https://phathanh.vneconomy.vn" target="_blank" style="display:flex;justify-content:center"><img style="width:50% !important;" src="https://media.vneconomy.vn/beta/images/anpham-vi.png" class="img-fluid"></a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'SiteHeader',
        data() {
            return {
                height: 0
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            var $siteHeader = document.getElementById('site-header');
            var headerHeight = this.outerHeight($siteHeader);
            this.height = headerHeight;
            var $nav = $siteHeader.querySelector('.nav');
            var $btnExpand = $siteHeader.querySelector('.btn-expand');
            $btnExpand.addEventListener('click', this.expandNav);

            var $boxUser = $siteHeader.querySelector('.box-user');
            $boxUser.addEventListener('click', () => {
                if (!this.currentUser) {
                    this.$router.push('/login');
                    return;
                }
                var $popupUserHeader = $siteHeader.querySelector('.popup-user-header');
                $popupUserHeader.classList.toggle('open-popup-user');
            });

            var $closePopup = $siteHeader.querySelector('.close-popup');
            if ($closePopup)
                $closePopup.addEventListener('click', this.closePopupUser);
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
            var $siteHeader = document.getElementById('site-header');
            var $btnExpand = $siteHeader.querySelector('.btn-expand');
            $btnExpand.removeEventListener('click', this.expandNav);
        },
        methods: {
            handleScroll(event) {
                var $siteHeader = document.getElementById('site-header');
                if (document.body.scrollTop > (this.height) || document.documentElement.scrollTop > (this.height)) {
                    $siteHeader.classList.add('is-pinned');
                    $siteHeader.style.height = this.height + 'px';
                } else {
                    $siteHeader.classList.remove('is-pinned');
                    $siteHeader.removeAttribute('style');
                }
            },
            outerHeight(el) {
                let style = window.getComputedStyle(el);
                let height = ['height', 'padding-top', 'padding-bottom']
                    .map((key) => parseInt(style.getPropertyValue(key), 10))
                    .reduce((prev, cur) => prev + cur);
                return height;
            },
            expandNav(e) {
                e.preventDefault();
                var $siteHeader = document.getElementById('site-header');
                var $nav = $siteHeader.querySelector('.nav');
                var $iconBar = document.querySelector('.btn-expand .icon-bars');
                var $iconTimes = document.querySelector('.btn-expand .icon-times');
                if ($nav.style.display === 'block') {
                    $nav.style.display = 'none';
                    $iconBar.style.display = 'block';
                    $iconTimes.style.display = 'none';
                } else {
                    $nav.style.display = 'block';
                    $iconBar.style.display = 'none';
                    $iconTimes.style.display = 'block';
                }
                var $body = document.querySelector('body');
                if ($body.style.overflow === 'hidden') {
                    $body.style.overflow = 'auto';
                } else {
                    $body.style.overflow = 'hidden';
                }
            },
            onClickOutside() {
                this.closePopupUser();
            },
            closePopupUser() {
                if (!this.currentUser) {
                    return;
                }
                var $siteHeader = document.getElementById('site-header');
                var $popupUserHeader = $siteHeader.querySelector('.popup-user-header');
                if ($popupUserHeader.classList.contains('open-popup-user')) {
                    $popupUserHeader.classList.remove('open-popup-user');
                }
            }
        },
        watch: {
            $route(to, from) {
                this.closePopupUser();
            }
        }
    };
</script>
