<template>
    <footer class="site-footer">
        <div class="container-xxl footer site-footer__info">
            <div class="bars">
                <div class="row">
                    <div class="col-md-6">
                        <a href="https://media.vneconomy.vn/res/qc/bao-gia-quang-cao.html" target="_blank" class="text-uppercase">Liên hệ quảng cáo</a>
                        <a href="https://vneconomy.vn" class="text-uppercase">Kết nối tòa soạn</a>
                    </div>
                    <div class="col-md-6 text-right d-none d-md-block">
                        <a href="https://vneconomy.vn" class="text-uppercase text-primary" title="Tạp chí kinh tế Việt Nam">
                            <b>Tạp chí kinh tế Việt Nam</b>
                        </a>
                        <a href="https://en.vneconomy.vn/" target="_blank" class="text-uppercase text-primary" title="Vietnam Economic Times">
                            <b>Vietnam Economic Times</b>
                        </a>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-6 col-lg-3">
                        <h2 class="logo d-none d-md-block">
                            <a href="https://vneconomy.vn">
                                <img src="https://media.vneconomy.vn/App_themes/images/logo.png" alt="VnEconomy - Tạp chí kinh tế Việt Nam" title="VnEconomy - Tạp chí kinh tế Việt Nam" class="img-fluid">
                            </a>
                        </h2>
                        <div class="d-md-none d-flex align-items-center justify-content-between" style="font-size: 0px;">
                            <h2 class="logo">
                                <a href="https://vneconomy.vn">
                                    <img src="https://media.vneconomy.vn/App_themes/images/logo.png" alt="VnEconomy - Tạp chí kinh tế Việt Nam" title="VnEconomy - Tạp chí kinh tế Việt Nam" class="img-fluid">
                                </a>
                            </h2>
                            <div class="logo-desc d-flex align-items-center">
                                <img src="https://media.vneconomy.vn/App_themes/images/favicon.ico" alt="VnEconomy" class="img-fluid mr-1" style="width:16px;"> <strong style="font-size:14px;">TẠP CHÍ ĐIỆN TỬ</strong>
                            </div>
                        </div>
                        <br>
                        <p>Tổng biên tập: </p>
                        <p>
                            <strong>TS. Chử Văn Lâm</strong>
                        </p>
                        <p>Tổng thư ký tòa soạn: </p>
                        <p>
                            <strong>Đào Quang Bính</strong>
                        </p>
                        <br>
                        <p>Giấy phép Tạp chí điện tử số: </p>
                        <p>
                            <strong>272/GP-BTTTT ngày 26/6/2020</strong>
                        </p>
                        <p class="text">
                            Phát triển bởi
                            <a style="margin-left: 4px;" href="https://hemera.vn/" title="Hemera Media" target="_blank">
                                <strong>Hemera Media</strong>
                            </a>
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-9 text-md-right">
                        <p>Bản quyền thuộc về VnEconomy, Tạp chí điện tử của Hội Khoa học Kinh tế Việt Nam</p><br>
                        <p>Mọi tin bài đăng lại từ website này phải có sự chấp thuận bằng văn bản của VnEconomy.</p><br>
                        <p>Các trang ngoài sẽ được mở ra ở cửa sổ mới. VnEconomy không chịu trách nhiệm nội dung các trang ngoài.</p>
                        <p>Trụ sở: Số 96-98 Hoàng Quốc Việt, Cầu Giấy, Hà Nội</p>
                        <p>Điện thoại: <a href="tel:02437552050" title="Điện thoại">02437552050</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'SiteFooter',
    };
</script>
