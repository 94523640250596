<template>
    <div class="main-block-user">
        <!--<div class="title-tab-user">
            <div class="content">
                <h3 class="name">Xin chào Hoàng Hồng A</h3>
                <p class="text-sub">Là thành viên từ tháng 7/2024</p>
            </div>
            <div class="box-avatar">
                <img class="image-avatar" src="https://mighty.tools/mockmind-api/content/human/7.jpg" alt="">
                <div class="icon-upload">
                    <img src="@/assets/img-fix/camera.svg" alt="">
                </div>
            </div>
        </div>-->
        <div class="main-tab-user">
            <div class="block-info-user">
                <!--<div class="title-site">
                    <p class="name">Askonomy</p>
                </div>-->
                <div class="main-block"> 
                    <div id="askconomy-wrapper">
                        <iframe src="https://app.chatbd.ai/929e7e8f-e40c-4094-891d-490a896594f7" frameborder="0" style="width: 100%; height: 100%;" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    #askconomy-wrapper { min-height: 500px; border: solid 1px #ccc; }
</style>
<script>
    export default {
        mounted() {
            document.title = 'Askonomy';
            setTimeout(() => {
                const iframe = document.querySelector('#askconomy-wrapper iframe');
                if (iframe) {
                    iframe.style.height = `${600}px`;
                }
            }, 100);
        },
    };
</script>
