<template>
    <div class="sidebar-block-user">
        <div class="layout-sidebar-block-user">
            <div class="main-link-user-header">
                <div class="imt-user">
                    <router-link to="/manager/info" class="link-user">
                        <span class="icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.875 7.5C14.875 6.40625 14.25 5.39062 13.3125 4.80469C12.3359 4.25781 11.125 4.25781 10.1875 4.80469C9.21094 5.39062 8.625 6.40625 8.625 7.5C8.625 8.63281 9.21094 9.64844 10.1875 10.2344C11.125 10.7812 12.3359 10.7812 13.3125 10.2344C14.25 9.64844 14.875 8.63281 14.875 7.5ZM6.75 7.5C6.75 5.74219 7.6875 4.10156 9.25 3.20312C10.7734 2.30469 12.6875 2.30469 14.25 3.20312C15.7734 4.10156 16.75 5.74219 16.75 7.5C16.75 9.29688 15.7734 10.9375 14.25 11.8359C12.6875 12.7344 10.7734 12.7344 9.25 11.8359C7.6875 10.9375 6.75 9.29688 6.75 7.5ZM4.91406 20.625H18.5469C18.1953 18.1641 16.0859 16.25 13.5078 16.25H9.95312C7.375 16.25 5.26562 18.1641 4.91406 20.625ZM3 21.3672C3 17.5 6.08594 14.375 9.95312 14.375H13.5078C17.375 14.375 20.5 17.5 20.5 21.3672C20.5 21.9922 19.9531 22.5 19.3281 22.5H4.13281C3.50781 22.5 3 21.9922 3 21.3672Z" fill="black"></path>
                            </svg>

                        </span>
                        <span class="name">Thông tin chung</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/history" class="link-user">
                        <span class="icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.875 6.67969C5.67188 4.17969 8.64062 2.5 12 2.5C17.5078 2.5 22 6.99219 22 12.5C22 18.0469 17.5078 22.5 12 22.5C9.92969 22.5 8.05469 21.9141 6.45312 20.8594C6.02344 20.5859 5.90625 20 6.21875 19.5703C6.49219 19.1406 7.07812 19.0234 7.50781 19.2969C8.79688 20.1562 10.3203 20.625 12 20.625C16.4531 20.625 20.125 16.9922 20.125 12.5C20.125 8.04688 16.4531 4.375 12 4.375C9.10938 4.375 6.57031 5.89844 5.125 8.125H7.3125C7.82031 8.125 8.25 8.55469 8.25 9.0625C8.25 9.60938 7.82031 10 7.3125 10H2.9375C2.39062 10 2 9.60938 2 9.0625V4.6875C2 4.17969 2.39062 3.75 2.9375 3.75C3.44531 3.75 3.875 4.17969 3.875 4.6875V6.67969ZM12 7.5H11.9609C12.5078 7.5 12.8984 7.92969 12.8984 8.4375V12.1484L15.4375 14.6875C15.8281 15.0391 15.8281 15.625 15.4375 15.9766C15.0859 16.3672 14.5 16.3672 14.1484 15.9766L11.3359 13.1641C11.1406 13.0078 11.0625 12.7734 11.0625 12.5V8.4375C11.0625 7.92969 11.4531 7.5 12 7.5Z" fill="black"></path>
                            </svg>

                        </span>
                        <span class="name">Lịch sử xem</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/comments" class="link-user">
                        <span class="icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 7H17V9H7V7ZM7 11H14V13H7V11Z" fill="black"></path>
                                <path d="M20 2H4C2.897 2 2 2.897 2 4V22L7.333 18H20C21.103 18 22 17.103 22 16V4C22 2.897 21.103 2 20 2ZM20 16H6.667L4 18V4H20V16Z" fill="black"></path>
                            </svg>

                        </span>
                        <span class="name">Bình luận của bạn (26)</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/saved" class="link-user">
                        <span class="icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.5 4.375C4.5 3.35938 5.32031 2.5 6.375 2.5V4.375V19.7656L11.4531 16.1328C11.7656 15.8984 12.1953 15.8984 12.5469 16.1328L17.625 19.7656V4.375H6.375V2.5H17.625C18.6406 2.5 19.5 3.35938 19.5 4.375V21.5625C19.5 21.9141 19.3047 22.2656 18.9922 22.4219C18.6797 22.5781 18.2891 22.5391 18.0156 22.3438L12 18.0469L5.94531 22.3438C5.67188 22.5391 5.32031 22.5781 5.00781 22.4219C4.69531 22.2656 4.5 21.9141 4.5 21.5625V4.375Z" fill="black"></path>
                            </svg>

                        </span>
                        <span class="name">Tin đã lưu (10)</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/premium" class="link-user">
                        <span class="icon">

                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.8438 6.125C20.2422 6.125 20.5703 6.26562 20.8281 6.54688C21.1094 6.80469 21.25 7.13281 21.25 7.53125C21.25 7.92969 21.1094 8.25781 20.8281 8.51562C20.5703 8.79688 20.2422 8.9375 19.8438 8.9375C19.8438 8.9375 19.832 8.9375 19.8086 8.9375L18.0508 18.7109C17.9102 19.2734 17.5352 19.5781 16.9258 19.625H5.32422C4.73828 19.5781 4.35156 19.2734 4.16406 18.7109L2.40625 8.9375C2.40625 8.9375 2.39453 8.9375 2.37109 8.9375C1.97266 8.9375 1.64453 8.79688 1.38672 8.51562C1.12891 8.25781 0.988281 7.92969 0.964844 7.53125C0.988281 7.13281 1.12891 6.80469 1.38672 6.54688C1.64453 6.26562 1.97266 6.125 2.37109 6.125C2.76953 6.125 3.09766 6.26562 3.35547 6.54688C3.63672 6.80469 3.77734 7.13281 3.77734 7.53125C3.77734 7.83594 3.68359 8.11719 3.49609 8.375L6.66016 10.8711C6.87109 11.0352 7.10547 11.1172 7.36328 11.1172C7.80859 11.0938 8.14844 10.8945 8.38281 10.5195L10.3516 6.44141C9.92969 6.18359 9.70703 5.79688 9.68359 5.28125C9.70703 4.88281 9.84766 4.55469 10.1055 4.29688C10.3867 4.01562 10.7266 3.875 11.125 3.875C11.5234 3.875 11.8516 4.01562 12.1094 4.29688C12.3672 4.55469 12.4961 4.88281 12.4961 5.28125C12.4727 5.79688 12.2617 6.18359 11.8633 6.44141L13.8672 10.5195C14.1016 10.918 14.4414 11.1172 14.8867 11.1172C15.1445 11.1172 15.3789 11.0352 15.5898 10.8711L18.7539 8.375C18.5664 8.14062 18.4609 7.85938 18.4375 7.53125C18.4375 7.13281 18.5781 6.80469 18.8594 6.54688C19.1172 6.26562 19.4453 6.125 19.8438 6.125ZM16.4688 17.9375L17.6641 11.3984L16.6445 12.207C16.1289 12.6055 15.543 12.8047 14.8867 12.8047C13.7617 12.7812 12.918 12.2656 12.3555 11.2578L11.125 8.76172L9.89453 11.2578C9.35547 12.2656 8.51172 12.7812 7.36328 12.8047C6.70703 12.8047 6.12109 12.6055 5.60547 12.207L4.58594 11.3984L5.78125 17.9375H16.4688Z" fill="black"></path>
                            </svg>

                        </span>
                        <span class="name">VnEconomy Premium</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/orders" class="link-user">
                        <span class="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.875 6.67969C5.67188 4.17969 8.64062 2.5 12 2.5C17.5078 2.5 22 6.99219 22 12.5C22 18.0469 17.5078 22.5 12 22.5C9.92969 22.5 8.05469 21.9141 6.45312 20.8594C6.02344 20.5859 5.90625 20 6.21875 19.5703C6.49219 19.1406 7.07812 19.0234 7.50781 19.2969C8.79688 20.1562 10.3203 20.625 12 20.625C16.4531 20.625 20.125 16.9922 20.125 12.5C20.125 8.04688 16.4531 4.375 12 4.375C9.10938 4.375 6.57031 5.89844 5.125 8.125H7.3125C7.82031 8.125 8.25 8.55469 8.25 9.0625C8.25 9.60938 7.82031 10 7.3125 10H2.9375C2.39062 10 2 9.60938 2 9.0625V4.6875C2 4.17969 2.39062 3.75 2.9375 3.75C3.44531 3.75 3.875 4.17969 3.875 4.6875V6.67969ZM12 7.5H11.9609C12.5078 7.5 12.8984 7.92969 12.8984 8.4375V12.1484L15.4375 14.6875C15.8281 15.0391 15.8281 15.625 15.4375 15.9766C15.0859 16.3672 14.5 16.3672 14.1484 15.9766L11.3359 13.1641C11.1406 13.0078 11.0625 12.7734 11.0625 12.5V8.4375C11.0625 7.92969 11.4531 7.5 12 7.5Z" fill="black"></path>
                            </svg>
                        </span>
                        <span class="name">Lịch sử giao dịch</span>
                    </router-link>
                </div>
                <div class="imt-user">
                    <router-link to="/manager/askonomy" class="link-user">
                        <span class="icon">
                            <img src="@/assets/img-fix/icon-askconomy.png" alt="">
                        </span>
                        <span class="name">Askonomy</span>
                    </router-link>
                </div>
            </div>
            <div class="bottom-user">
                <div class="btn-logout">
                    <router-link to="/logout" class="link-logout">
                        <span class="text">Đăng xuất</span>
                        <span class="icon">

                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5625 2.625H1.875V16.375H6.5625H7.5V18.25H6.5625H0.9375H0V17.3125V1.6875V0.75H0.9375H6.5625H7.5V2.625H6.5625ZM19.2578 10.1641L14.2578 15.1641L13.5938 15.8281L12.2656 14.5L12.9297 13.8359L16.3281 10.4375H7.1875H6.25V8.5625H7.1875H16.3281L12.9297 5.16406L12.2656 4.5L13.5938 3.17188L14.2578 3.83594L19.2578 8.83594L19.9219 9.5L19.2578 10.1641Z" fill="black"></path>
                            </svg>

                        </span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="box-select-mobile-sidebar">
            <span class="text">Thông tin chung</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        name: 'Left',
        watch: {
            '$route'(to, from) {
                this.closeMenuMobile();
            }
        },
        mounted() {
            setTimeout(() => {
                var $boxSelectMobileSidebar = document.querySelector('.box-select-mobile-sidebar');
                var $layoutSidebarBlockUser = document.querySelector('.layout-sidebar-block-user');
                $boxSelectMobileSidebar.addEventListener('click', function () {
                    $layoutSidebarBlockUser.classList.toggle('open-sidebar');
                });
            }, 100);
        },
        methods: {
            closeMenuMobile() {
                var $layoutSidebarBlockUser = document.querySelector('.layout-sidebar-block-user');
                $layoutSidebarBlockUser.classList.remove('open-sidebar');
            },
        },
    }
</script>
