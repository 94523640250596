<template>
    <div class="wrap">
        <siteHeader></siteHeader>
        <main>
            <router-view></router-view>
        </main>
        <siteFooter></siteFooter>
    </div>
</template>
<script>
    import siteHeader from '../components/layout/site-header.vue'
    import siteFooter from '../components/layout/site-footer.vue'
    export default {
        components: { siteHeader, siteFooter },
        created() { },
        mounted() {
            if (document)
                document.body.classList.add('ct-reponsive')
        }
    };
</script>
