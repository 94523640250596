<template>
    <div class="container-xxl signup-page">
        <div class="layout-login-page">
            <div class="content-login-page">
                <div class="box-login-ct">
                    <template v-if="step == 1">
                        <div class="title-login">
                            <h2 class="txt-big-title-login">{{ $t("register") }}</h2>
                            <p class="txt-small-title-login">Mời bạn nhập thông tin để khởi tạo VnEconomy ID.</p>
                        </div>
                        <div class="content-box-ct">
                            <div class="form-info-new">
                                <div class="ct-input-info-new">
                                    <label class="label-bold">{{$t('email_or_phonenumber')}}</label>
                                    <input type="text" id="ipt-emailorphone" name="email" v-model="userInfo.username" @keyup.enter="checkAndSendOTP" class="ipt">
                                </div>
                            </div>
                        </div>
                        <div class="group-bottom-btn-popup">
                            <div class="right-bottom-btn-popup">
                                <div class="btn-save">
                                    <button type="button" class="btn-bg-linear" @click="checkAndSendOTP">
                                        <span class="txt">{{ $t("register_next_step") }}</span>
                                        <span class="icon"><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7871 4.85381L10.6961 0.187141C10.5589 0.0657028 10.3752 -0.00149374 10.1845 2.52016e-05C9.99384 0.00154415 9.81143 0.0716569 9.67659 0.195262C9.54175 0.318868 9.46526 0.486076 9.4636 0.660874C9.46194 0.835672 9.53525 1.00407 9.66773 1.12981L13.5172 4.65847H0.72728C0.534393 4.65847 0.349407 4.72871 0.213015 4.85374C0.0766239 4.97876 0 5.14833 0 5.32514C0 5.50195 0.0766239 5.67152 0.213015 5.79655C0.349407 5.92157 0.534393 5.99181 0.72728 5.99181H13.5172L9.66773 9.52047C9.59827 9.58197 9.54286 9.65553 9.50474 9.73687C9.46663 9.81821 9.44657 9.90569 9.44573 9.99421C9.44489 10.0827 9.46329 10.1705 9.49986 10.2524C9.53643 10.3344 9.59043 10.4088 9.65872 10.4714C9.727 10.534 9.8082 10.5835 9.89758 10.617C9.98696 10.6505 10.0827 10.6674 10.1793 10.6666C10.2759 10.6659 10.3713 10.6475 10.46 10.6125C10.5488 10.5776 10.629 10.5268 10.6961 10.4631L15.7871 5.79647C15.9234 5.67146 16 5.50192 16 5.32514C16 5.14837 15.9234 4.97883 15.7871 4.85381Z" fill="white"></path></svg></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="step == 2">
                        <div class="title-login">
                            <h2 class="txt-big-title-login">{{ $t("register") }}</h2>
                            <p class="txt-small-title-login"><span class="txt-lbl">{{ $t("account") }}: <em>{{ userInfo.username }}</em></span></p>
                        </div>
                        <div>
                            <div class="content-box-ct">
                                <div class="ct-input-info-new">
                                    <label for="ipt-emailorphone" class="label-bold">{{$t('otp_input')}}</label>
                                    <input type="text" id="ipt-emailorphone" class="ipt" v-model="otp">
                                    <a href="javascript:;" class="resend-authen-code disable" v-if="seconds > 0">
                                        {{ $t("otp_resend_in") }}
                                        <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                                    </a>
                                    <a href="javascript:;" class="resend-authen-code" @click="resendOTP" v-else>
                                        <svg enable-background="new 0 0 70 70" height="70px" id="Icons" version="1.1" viewBox="0 0 70 70" width="70px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M51.211,30.05V18.789L47.01,22.99C43.937,19.91,39.695,18,35,18c-9.389,0-17,7.611-17,17s7.611,17,17,17  c6.319,0,11.82-3.455,14.75-8.571l-3.475-1.986C44.032,45.354,39.823,48,35,48c-7.168,0-13-5.832-13-13c0-7.168,5.832-13,13-13  c3.585,0,6.828,1.464,9.18,3.82l-4.23,4.23H51.211z" /></svg>
                                        {{$t('register_resend_otp') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="group-bottom-btn-popup">
                            <div class="left-bottom-btn-popup">
                                <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $t("cancel") }}</a>
                            </div>
                            <div class="right-bottom-btn-popup">
                                <div class="btn-save">
                                    <button type="button" class="btn-bg-linear" @click="checkOTP">
                                        <span class="txt">{{ $t("check") }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="step == 3">
                        <h3>{{ $t("register") }}</h3>
                        <ul class="form-register">
                            <li class="custom-input">
                                <input type="text" id="ipt-lastname" class="ipt" v-model="userInfo.fullName">
                                <label for="ipt-lastname" class="lbl">Họ và tên</label>
                            </li>
                            <li class="custom-input">
                                <input-password v-model="userInfo.password" @enter="updateInfo" :label="$t('password')" />
                            </li>
                            <li class="custom-input">
                                <input-password v-model="userInfo.rePassword" @enter="updateInfo" :label="$t('repassword')" />
                            </li>
                        </ul>
                        <span class="text-center txt-date-birth">{{$t('dob')}}</span>
                        <date-dropdown v-model="userInfo.birthdate"
                                       min="1800"
                                       :max="new Date().getFullYear() - 5 + ''"
                                       class="m-b-25" />
                        <div class="btn-area">
                            <button type="button" class="btn" @click="updateInfo">{{$t("update")}}</button>
                        </div>
                        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $t("cancel") }}</a>
                    </template>
                    <div id="register-call-action">
                        <p class="txt-register"><router-link to="/login" class="txt-link">{{ $t("login_call_to_action_msg") }} {{ $t("login_now") }}</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                step: 1,
                userInfo: {
                    id: 0,
                    username: '',
                    address: '',
                    birthdate: (new Date().getFullYear() - 5) + '/1/1'
                },
                otp: '',
                seconds: 0,
            };
        },
        created() {
            this.checkUserProcessRegist();
        },
        methods: {
            checkUserProcessRegist() {
                const cookieUserName = this.$cookie.get(this.$cookieConstants.REGISTER_USER_NAME);
                if (cookieUserName) {
                    this.userInfo.username = cookieUserName;
                    const $loading = this.$loading.show();
                    this.$axios
                        .post(this.$api.ACCOUNT_CHECK_PROCESS_REGIST, {
                            username: this.userInfo.username,
                        })
                        .then((data) => {
                            $loading.hide();
                            if (!data.result) {
                                this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                            } else {
                                this.step = 2;
                                this.setTimerOTP(data.result.times);
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                            this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                        });
                }
            },
            checkAndSendOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_USERNAME_AND_SEND_OTP, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t('enter_security_code'));
                        this.step = 2;
                        this.setTimerOTP(data.result.times);
                        this.$cookie.set(this.$cookieConstants.REGISTER_USER_NAME, this.userInfo.username);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            setTimerOTP(seconds) {
                this.seconds = seconds;
                this.intervalTimer = setInterval(() => {
                    if (this.seconds <= 0) {
                        clearInterval(this.intervalTimer);
                    }

                    this.seconds--;
                }, 1000);
            },
            resendOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_RESEND_OTP, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.setTimerOTP(data.result.times);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            checkOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_OTP_ADD_USER, {
                        username: this.userInfo.username,
                        otp: this.otp,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.userInfo.id = data.result;
                        this.step = 3;
                        this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            cancelUpdateInfo() {
                this.$router.push('/');
            },
            updateInfo() {
                if (!this.userInfo.firstName) {
                    return this.$error(this.$t('error_first_name_empty'));
                }
                if (!this.userInfo.lastName) {
                    return this.$error(this.$t('error_last_name_empty'));
                }
                if (!this.userInfo.password) {
                    return this.$error(this.$t('error_password_empty'));
                } else {
                    if (!this.checkPassword(this.userInfo.password)) {
                        return this.$error(this.$t('error_password_invalid'));
                    }
                }
                if (!this.userInfo.rePassword) {
                    return this.$error(this.$t('error_repassword_empty'));
                }
                if (this.userInfo.password || this.userInfo.rePassword) {
                    if (this.userInfo.password !== this.userInfo.rePassword)
                        return this.$error(this.$t('error_confirm_new_password'));
                }
                if (!this.userInfo.birthdate) {
                    return this.$error(this.$t('error_birth_date_empty'));
                }
                if (!this.userInfo.gender) {
                    return this.$error(this.$t('error_gender_empty'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_UPDATE_USER, this.userInfo)
                    .then((_) => {
                        $loading.hide();
                        this.$router.push('/');
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            cancel() {
                this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                this.step = 1;
            },
        },
    };
</script>
