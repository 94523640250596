<template>
    <div class="main-block-user">
        <!--<div class="title-tab-user">
            <div class="content">
                <h3 class="name">Xin chào Hoàng Hồng A</h3>
                <p class="text-sub">Là thành viên từ tháng 7/2024</p>
            </div>
            <div class="box-avatar">
                <img class="image-avatar" src="https://mighty.tools/mockmind-api/content/human/7.jpg" alt="">
                <div class="icon-upload">
                    <img src="@/assets/img-fix/camera.svg" alt="">
                </div>
            </div>
        </div>-->
        <div class="main-tab-user">
            <div class="block-info-user">
                <div class="title-site">
                    <p class="name">Lịch sử giao dịch</p>
                </div>
                <div class="main-block">
                    <div class="container mt-5">
                        <div class="d-flex justify-content-center row">
                            <div class="col-md-12">
                                <div class="rounded">
                                    <div class="table-responsive table-borderless">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <div class="toggle-btn">
                                                            <div class="inner-circle"></div>
                                                        </div>
                                                    </th>
                                                    <th>Mã #</th>
                                                    <th style="min-width:200px;">Sản phẩm</th>
                                                    <th>Trạng thái</th>
                                                    <th>Tổng tiền</th>
                                                    <th>Ngày</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr class="cell-1">
                                                    <td class="text-center">
                                                        <div class="toggle-btn">
                                                            <div class="inner-circle"></div>
                                                        </div>
                                                    </td>
                                                    <td>#E-13487</td>
                                                    <td>Tạp chí kinh tế Việt Nam #44</td>
                                                    <td><span class="badge badge-success">Thành công</span></td>
                                                    <td>60.000đ</td>
                                                    <td>Hôm nay</td>
                                                    <td><i class="fa fa-ellipsis-h text-black-50"></i></td>
                                                </tr>
                                                <tr class="cell-1">
                                                    <td class="text-center">
                                                        <div class="toggle-btn">
                                                            <div class="inner-circle"></div>
                                                        </div>
                                                    </td>
                                                    <td>#E-13453</td>
                                                    <td>Tạp chí kinh tế Việt Nam #43</td>
                                                    <td><span class="badge badge-info">Chờ thanh toán</span></td>
                                                    <td>60.000đ</td>
                                                    <td>17/10</td>
                                                    <td><i class="fa fa-ellipsis-h text-black-50"></i></td>
                                                </tr>
                                                <tr class="cell-1">
                                                    <td class="text-center">
                                                        <div class="toggle-btn">
                                                            <div class="inner-circle"></div>
                                                        </div>
                                                    </td>
                                                    <td>#E-13498</td>
                                                    <td>Gói tài khoản Premium 1 năm</td>
                                                    <td><span class="badge badge-success">Thành công</span></td>
                                                    <td>999.000đ</td>
                                                    <td>15/05</td>
                                                    <td><i class="fa fa-ellipsis-h text-black-50"></i></td>
                                                </tr>
                                                <tr class="cell-1">
                                                    <td class="text-center">
                                                        <div class="toggle-btn">
                                                            <div class="inner-circle"></div>
                                                        </div>
                                                    </td>
                                                    <td>#E-16499</td>
                                                    <td>Tạp chí kinh tế Việt Nam #40 (điện tử)</td>
                                                    <td><span class="badge badge-danger">Quá hạn thanh toán</span></td>
                                                    <td>15.000đ</td>
                                                    <td>11/03</td>
                                                    <td><i class="fa fa-ellipsis-h text-black-50"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    #askconomy-wrapper { min-height: 500px; border: solid 1px #ccc; }
</style>
<script>
    export default {
    };
</script>
